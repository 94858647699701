<template>
  <div>
    <div class="admintop-mian ">
      <div>
       <template v-if="language=='zh'">
         <myimage :url='url'></myimage>
       </template>
       <template v-if="language=='TW'">
          <myimage :url='urlTW'></myimage>
        </template>
        <template v-if="language=='En'">
          <myimage :url='urlEn'></myimage>
        </template>
      </div>
      <div :class="screenWidth>=992?' hidden-xs-only adminmain-service':'hidden-xs-only adminmain-service-sm adminmainpaddbottom70 ' ">
        <div class="">
          <div class="seachina-text">{{$t('entrance.首页')}}</div>
          <div class="seaenglish-text">{{$t('entrance.PortromptType')}}</div>
        </div>
        <div class="admissCon">
          <!-- 左边的点击内容 -->
          <div class="left-con">
            <div @click="toleftindex(index,item.schoolId)" v-for="(item,index) in lefttype" :key='item.schoolId'
                 :class="leftindex==index?'leftactive':''">{{ item.name }}
            </div>
          </div>
          <!-- 右边的列表内容 -->
          <div class="admissright-con">
            <div @click="toDetail(item.presentId)"  v-for="(item,index) in schollList" :key="index">
              <div class="admissright-con-img">
                <img :src="baseUrl+item.thumbnail" alt="">
              </div>
              <div>
                <div class="admiss-top">{{item.title}}</div>
                <div class="admiss-con" >
                  <template v-if="item.content.replace(/<[^>]+>/g, '').length>100">{{item.content.replace(/<[^>]+>/g, '').slice(0,100)}}...</template>
                  <template v-else>{{item.content.replace(/<[^>]+>/g, '')}}</template>
                </div>
                <div class="admiss-icon">
                  <span class="titleicon">{{lefttype[leftindex].name}}</span>
                  <div>
                    <img src="../../assets/image/HKgoods/hot.png" alt="">
                    {{item.viewsCount||0}}
                  </div>
                  <div>
                    <img src="../../assets/image/HKgoods/time.png" alt="">
                     {{item.createDate.slice(5,10)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <!-- <div class="Hkpagination">
          <MyPagination
              :query='query'
              :total="1000">
          </MyPagination>
        </div> -->
        <div class="HKmain-service-bot" :style="[{'height':companyheight}]">
          <div class="english-yellow">{{$t('entrance.APPLICATIONPROCESS')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('entrance.支持')}}</div>
            <div class="servicebot"></div>
            <div class="all-service-company" >
              <div v-for="(item,index) in agencList" :key='index'  >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- 移动端 -->
      <div class="adminmain-service-sm-phone hidden-sm-and-up adminmainpaddbottom20">
        <div class="admin-list-choose">
          <div v-for="(item,index) in lefttype" :key='index' @click="toleftindex(index,item.schoolId)"  :class="leftindex==index?'leftactive':''">{{item.name}}</div>
        </div>
        <div class="admissright-con-sm">
          <div @click="toDetail(item.presentId)"  v-for="(item,index) in schollList" :key='index'>
              <div class="admissright-con-img-sm">
                <img :src="baseUrl+item.thumbnail" alt="">
              </div>
              <div>
                <div class="admiss-top-sm">{{item.title}}</div>
                <div class="admiss-con-sm" >
                  <template v-if="item.content.replace(/<[^>]+>/g, '').length>150">{{item.content.replace(/<[^>]+>/g, '').slice(0,150)}}...</template>
                  <template v-else>{{item.content.replace(/<[^>]+>/g, '')}}</template>
                </div>
                <div class="admiss-icon-sm">
                  <span class="titleicon">{{lefttype[leftindex].name}}</span>
                  <div>
                    <img src="../../assets/image/HKgoods/hot.png" alt="">
                 {{item.viewsCount||0}}
                  </div>
                  <div>
                    <img src="../../assets/image/HKgoods/time.png" alt="">
                    {{item.createDate.slice(5,10)}}
                  </div>
                </div>
              </div>
          </div>
        </div>
        <!-- 分页 -->
        <!-- <div class="Hkpagination" style="margin-top:10PX">
          <MyPagination
              :query='query'
              :total="100">
          </MyPagination>
        </div> -->
        <div class="main-service-bot-sm" :style="[{'height':companyheight2}]" >
          <div class="english-yellow-sm">{{$t('entrance.APPLICATIONPROCESS')}}</div>
          <div class="service-con-sm">
            <div class="servicetop-sm">{{$t('entrance.支持')}}</div>
            <div class="servicebot-sm"></div>
            <div class="all-service-company-sm" >
              <div v-for="(item,index) in agencList" :key='index' :style="[{'margin-right':companymargin}]" >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
    <Mydialog ref="icon" @submit="submitchange"></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
    </div>
</template>

<script>
import myimage from '@/components/myimage'
import "element-ui/lib/theme-chalk/display.css";
import Mydialog from "@/components/dialog"
import Myicon from '@/components/mainicon'
import {getSchool,getSchoolPresentList,agencList,addSchoolPresentViewsCount,addMessageRecord} from '@/api/index.js'
export default {
  components: {
    myimage,
    Mydialog,
    Myicon
  },
  data() {
    return {
      query: {
        pageNumber: 1,
        pageSize: 10
      },
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      lefttype: [],
      leftindex:0,
      addressId:'',
      schollList:[],
      urlTW:require('@/assets/image/xggxTW.png'),
      url:require('@/assets/image/xggx.png'),
      urlEn:require('@/assets/image/xggxEn.png'),
      baseUrl:'',
      agencList:[],
      language:'zh'
    }
  },

  filters: {
    localData(value) {
      let date = new Date(value * 1000);
      let Month = date.getMonth() + 1;
      let Day = date.getDate();
      let Y = date.getFullYear() + '年';
      let M = Month < 10 ? '0' + Month + '⽉' : Month + '⽉';
      let D = Day + 1 < 10 ? '0' + Day + '⽇' : Day + '⽇';
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let hour = hours < 10 ? '0' + hours + ':' : hours + ':';
      let minute = minutes < 10 ? '0' + minutes : minutes;
      return Y + M + D + ' ' + hour + minute;
    }
  },
  created(){
    this.addressId = this.$route.query.addressId
    this._getSchool()
    this._agencList()
    this.baseUrl=this.$store.state.baseUrl
    this.language=localStorage.getItem('language')
  },
  computed:{
    companyheight(){
      if(this.agencList.length>0){
     return  Math.ceil(this.agencList.length/4)*110+200 +'PX'
      }else{
         return 300+'PX'
      }
    },
     companyheight2(){
      if(this.agencList.length>0){
        return (Math.ceil(this.agencList.length/Math.floor( 335/100))*110+40)+'PX'
      }else{
         return 100+'PX'
      }
    },
    companymargin(){
      if(this.agencList.length>0){
        return Math.floor(( this.screenWidth*0.9-Math.floor(this.screenWidth*0.9/224)*224)/(Math.floor(this.screenWidth*0.9/224)-1))+'PX'
      }else{
         return 0+'PX'
      }
    }
  },
  watch:{
    $route(to) {
  // 监听路由是否变化
  if (to.query.addressId !== this.addressId ) {
    this.addressId = to.query.addressId
    this.init() // 直接处理获取数据的部分就行，页面不闪。
    // location.reload() // 这个是刷新整个页面，会闪一下。
    console.log('当前页刷新！')
  }
}
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
        if (document.body.clientWidth <= 768) {
                 location.reload();
        }
      })();
    };
    
  },
  methods: {
    
    //在线咨询（院校入口）
    submitchange(e){
        e.category=4
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    init(){
      this._getSchool()
    },
    //获取合作
      _agencList(){
        agencList().then(res=>{
          console.log(res)
          res.data.forEach((item)=>{
            item.thumbnail=this.baseUrl+item.thumbnail
          })
          this.agencList=res.data
        })
      },
    //获取学校
    _getSchool(){
      this.lefttype=[]
      getSchool({
        addressId:this.addressId
      }).then(res=>{
        this.lefttype=res.data
        if(res.data.length>0){
          this. _getSchoolPresentList(res.data[0].schoolId)
        }else{
          this.schollList=[]
        }

      })
    },
//获取学校介绍列表
    _getSchoolPresentList(schoolId){
      getSchoolPresentList({
        schoolId:schoolId
      }).then(res=>{
        this.schollList=res.data
      })
    },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
    toleftindex(e,schoolId) {
      this.leftindex = e
      this._getSchoolPresentList(schoolId)
    },
    //增加学校介绍详情浏览量
    toDetail(presentId) {
      addSchoolPresentViewsCount({
        presentId:presentId
      }).then(res=>{
        console.log('增加学校介绍详情浏览量',res)
        this.$router.push({
          path: '/Overseas-Study',
          query:{
            presentId:presentId
          }
        });
      })
       
    }
  }
}
</script>

<style scoped lang="scss">
.admintop-mian {
  width: 100vw;
  background: #f7f7f7 !important;
}

.Hkpagination {
  margin: 0 auto;
  text-align: center !important;
}

.adminmain-service {
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
  padding: 70PX 0;
}

.adminmain-service-sm {
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}
.adminmain-service-sm-phone {
  width: 335PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}
.adminmainpaddbottom70{
  padding: 70PX 0;
}
.adminmainpaddbottom20{
  padding: 20PX 0 0 0;
}
.adminmain-service > div, .adminmain-service-sm > div,.adminmain-service-sm-phone>div {
  position: relative;
  top: 0;
  text-align: left;
}

.seachina-text {
  font-size: 20PX;
  font-weight: 600;
}

.seaenglish-text {
  padding-bottom: 24px;
  font-size: 28PX;
  color: #999999;
}

.admissCon {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-con {
  font-size: 16PX;
  width: 250PX;
}

.left-con > div {
  height: 54PX;
  line-height: 54PX;
  text-align: center;
  color: #333;
  background: #fff;
}

.leftactive {
  background: rgba(254, 153, 43, 0.2) !important;
  font-weight: bold;
}

.admissright-con {
  width: calc(100% - 274PX);
  display: flex;
  flex-direction: column;
}

.admissright-con > div {
  display: flex;
  flex-direction: row;
  margin-bottom: 30PX;
  height: 156PX;
}
.admissright-con-img{
  width: 228PX;
  height: 144PX;
  margin-right: 16px;
}
.admissright-con-img img {
  width: 228PX!important;
  height: 144PX!important;
}

.admiss-top {
  font-size: 20PX;
  margin-bottom: 12PX;
  color: #333;
  font-weight: bold;
}

.admiss-con {
  color: #999999;
  font-size: 16PX;
  height: 70PX;
::v-deep{
line-height: 24PX;
}
}

.admiss-icon {
  display: flex;
  flex-direction: row;
  margin-top: 13PX;
  font-size: 16PX;
}

.titleicon {
  background: rgba(254, 153, 43, 0.2);
  border-radius: 4PX;
  padding: 3PX 6PX;
  color: #FE992B;
  font-size: 12PX;
  margin-right: 20PX;
}

.admiss-icon > div {
  margin-right: 26PX;
}

.admiss-icon img {
  width: 23px;
  height: 23px;
  vertical-align: -3PX;
}

/* .HKmain-service-bot {
  height: 606px;
  text-align: center !important;
  padding: 70PX 0;
} */
.HKmain-service-bot {
  padding: 30PX 0;
}

.all-service-company{
  width:1176PX;
  padding: 30PX 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20PX auto 0 auto;
}
.all-service-company>div{
  width: 280PX;
  height: 100PX;
  margin-bottom: 30PX;
  //margin:0 calc(25% - 320PX) 30PX calc(25% - 320PX);
}
.all-service-company>div img{
  width: 280PX!important;
  height: 100PX;
}
.english-yellow {
  color: #FE992B;
  font-size: 44px;
  opacity: 0.3;
  text-align: center;
}

.service-con {
  width: 100%;
  position: absolute;
  top: 40px;
}

.servicetop {
  /* width: 150px; */
  font-size: 32px;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  /* border-bottom: 6px solid #FE992B; */
  border-radius: 3px;
  margin: 0 auto;
  text-align: center;
}

.servicebot {
  width: 150px;
  height: 6PX;
  background: #FE992B;
  border-radius: 3px;
  margin: 0 auto;
}

.all-service {
  width: 100%;
  height: 392px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}

.all-service img{
   width: 100%;
  height: 392px;
}
/* 移动 */

.admin-list-choose{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.admin-list-choose>div{
  width: 30%;
  height: 42PX;
  background: #fff;
  text-align: center;
  line-height: 42PX;
  font-size: 12PX;
  margin-bottom: 10PX;
}

.admissright-con-sm {
  width: 335PX;
  display: flex;
  flex-direction: column;
  margin-top: 16PX ;
  
}

.admissright-con-sm > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10PX;
}

.admissright-con-img-sm img {
  width: 335PX;
  height: 212PX;
  margin-right: 16px;
}
.admiss-top-sm {
  font-size: 16PX;
  margin: 12PX 0;
  color: #333;
}
.admiss-con-sm {
  color: #999999;
  font-size: 14PX;
}

.admiss-icon-sm {
  display: flex;
  flex-direction: row;
  margin: 13PX 0 22PX 0;
  font-size: 16PX;
}

.admiss-icon-sm > div {
  margin-right: 26PX;
}

.admiss-icon-sm img {
  width: 20PX;
  height: 20PX;
  vertical-align: -3PX;
}
.companypng-sm {
  width: 100%;
  height: 362PX;
  margin-top: 16PX;
}
.admiss-service-main{
  width: 100vw;
  position: relative;
  left: -5vw;
}
.main-service-bot-sm{
  height: 450PX;
  margin: 0 auto;
  padding-top: 20PX;
  background: #fff;
} 
.service-con-sm {
  width: 90vw;
  position: absolute;
  top: 20PX;
  /* left: 5vw; */
}

.servicetop-sm {
  /* width: 150px; */
  font-size: 20PX;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  border-radius: 3PX;
  margin: 0 auto;
  text-align: center;
}

.servicebot-sm {
  width: 69PX;
  height: 3PX;
  background: #FE992B;
  border-radius: 3PX;
  margin: 0 auto;
}
.english-yellow-sm {
  color: #FE992B;
  font-size: 20PX;
  opacity: 0.3;
  font-weight: bold;
  text-align: center;
}

.all-service-company-sm{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20PX;
}
.all-service-company-sm>div,.all-service-company-sm>div img{
  width:110PX;
  height: 60PX;
  margin-bottom: 20PX;
}
</style>